import axios from 'axios';
// import store from './../Store';
// import { setUnauthorized } from './../Store/action/userAction';

/**
 * Axios API request for users make requets
 * @param {String} endpoint End point of API
 * @param {String} method post, get, put, delete
 * @param {any} data Data to send
 * @param {boolean} formData if request must be used as formData
 * @param {String} completeUrl URL complete 
 * @param {String} token token for a particular request 
 */
export const AXIOS_REQUEST = (url, method = "get", data = null, formData = true, completeUrl = null, token = null) => {
    var headers = {
        'Authorization': `Bearer ${token || (localStorage.getItem("assistant/token"))}`,
        'Content-Type': 'application/json'
    }
    let params = null;
    if (method !== "get" && method !== "delete" && formData) {
        headers = {
            ...headers,
            'Process-Data': false,
            "Content-Type": false
        }
    } else if (method === "get" || method === "delete") {
        params = data;
    }

    return axios({
        method,
        url: completeUrl || url,
        data,
        params,
        headers
    }).then(resp => {
        // console.log({ resp });
        return resp?.data
    }).catch(err => {
        let error = { error: true }
        // console.log({ err, error })
        // if (err.response?.status === 401) {
        //     console.log("UNAUTHORIZED");
        //     error = { ...err.response, ...error, data: null };
        //     // if (window.location.href.search(/\/[#]\/ingresar\?/) === -1) {
        //     //     store.dispatch(setUnauthorized(true))
        //     //     window.location.href = `${window.location.href.split("#/")[0]}#/ingresar`;
        //     // }
        //     if ((/.+#\/ingresar\/?/.test(window.location.href)) || (/.+#\/inscripciones\/nueva\/?/.test(window.location.href))) {
                
        //     } else {
        //         store.dispatch(setUnauthorized(true))
        //         window.location.href = `${window.location.href.split("#/")[0]}#/ingresar`;
        //     }
        // }
        return error;
    })
}
