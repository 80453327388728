import React, { Component } from 'react';

const email_temp = (err = "") => "mailto:neider.galofre@uninunez.edu.co?subject=Error%20en%20aplicacion%20inscripciones&body=Hola%2C%20te%20env%C3%ADo%20la%20informaci%C3%B3n%20sobre%20el%20error%20%0D%0A%0D%0A" + err;

class ErrorHandler extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        // Actualiza el estado para que el siguiente renderizado muestre la interfaz de repuesto
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        console.log("========ERROR========");
        console.log({ error, errorInfo });
        console.log("=====================");
        if (error?.message && /Loading chunk [\d]+ failed/ig.test(error.message)) {
            console.log("chunk failed");
            window.location.reload(true);
        } else {
            let data = {
                error,
                message: error.message || "NG",
                stack: error.stack || "NG",
                errorInfo,
                path: window.location.href
            }
            this.setState({ error: JSON.stringify(data) })
        }
        // this.setState({error: `${JSON.stringify(error)} %20%0D%0A%0D%0A` })
        // Registrar el error en un servicio de reporte de errores
        // logErrorToMyService(error, errorInfo);
    }

    reloadChildren = () => { window.location.reload() }

    render() {
        if (this.state.hasError) {
            if (!(window.navigator?.onLine)) {
                return (<div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
                    <div className="col-12 mt-4 text-center" style={{ color: "black !important" }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor" className="bi bi-wifi-off" viewBox="0 0 16 16">
                            <path d="M10.706 3.294A12.545 12.545 0 0 0 8 3C5.259 3 2.723 3.882.663 5.379a.485.485 0 0 0-.048.736.518.518 0 0 0 .668.05A11.448 11.448 0 0 1 8 4c.63 0 1.249.05 1.852.148l.854-.854zM8 6c-1.905 0-3.68.56-5.166 1.526a.48.48 0 0 0-.063.745.525.525 0 0 0 .652.065 8.448 8.448 0 0 1 3.51-1.27L8 6zm2.596 1.404.785-.785c.63.24 1.227.545 1.785.907a.482.482 0 0 1 .063.745.525.525 0 0 1-.652.065 8.462 8.462 0 0 0-1.98-.932zM8 10l.933-.933a6.455 6.455 0 0 1 2.013.637c.285.145.326.524.1.75l-.015.015a.532.532 0 0 1-.611.09A5.478 5.478 0 0 0 8 10zm4.905-4.905.747-.747c.59.3 1.153.645 1.685 1.03a.485.485 0 0 1 .047.737.518.518 0 0 1-.668.05 11.493 11.493 0 0 0-1.811-1.07zM9.02 11.78c.238.14.236.464.04.66l-.707.706a.5.5 0 0 1-.707 0l-.707-.707c-.195-.195-.197-.518.04-.66A1.99 1.99 0 0 1 8 11.5c.374 0 .723.102 1.021.28zm4.355-9.905a.53.53 0 0 1 .75.75l-10.75 10.75a.53.53 0 0 1-.75-.75l10.75-10.75z" />
                        </svg>
                        <br /><br /><br />
                        <h3 >Conexión inestable</h3>
                        <small>Verifica que estés conectado a una red</small>
                        <br /><br />
                        <br /><br />
                        <button className="btn btn-warning text-white" onClick={() => { this.reloadChildren() }}>Reintentar</button>
                    </div>
                </div>)
            }
            return (
                <div style={{ height: "100vh" }} className="d-flex align-items-center justify-content-center">
                    <div style={{ "height": "90%", "width": "90%", "borderRadius": "15px" }} className="bg-light p-4 text-center ">
                        <div className="d-flex align-items-center h-100 w-100  justify-content-center">
                            <div className="p-3 opacity-05-infinity  text-muted">
                                <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" fill="currentColor" className="bi bi-emoji-dizzy" viewBox="0 0 16 16">
                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                    <path d="M9.146 5.146a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708.708l-.647.646.647.646a.5.5 0 0 1-.708.708l-.646-.647-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zm-5 0a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 1 1 .708.708l-.647.646.647.646a.5.5 0 1 1-.708.708L5.5 7.207l-.646.647a.5.5 0 1 1-.708-.708l.647-.646-.647-.646a.5.5 0 0 1 0-.708zM10 11a2 2 0 1 1-4 0 2 2 0 0 1 4 0z" />
                                </svg>
                                <div className=" mt-4">
                                    <h4 >Ops, Parece que hubo un error en la aplicación</h4>
                                </div>
                                <div className="mt-5">
                                    <button className="btn btn-secondary btn-md" onClick={() => { this.reloadChildren() }}>Recargar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

export default ErrorHandler;
