import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import ErrorHandler from './components/ErrorHandler';
import lazyLoaderComponents from './services/lazyLoaderComponents';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AXIOS_REQUEST } from './services/axiosService';
import './bootstrap.min.css';
import './index.css';

const ChooseProgram = lazy(() => lazyLoaderComponents(() => import("./ChooseProgram")));
const AllPrograms = lazy(() => lazyLoaderComponents(() => import("./AllPrograms")));
const ProgramInfo = lazy(() => lazyLoaderComponents(() => import("./components/Forms/ProgramInfo")));
const App = lazy(() => lazyLoaderComponents(() => import("./App")));

AXIOS_REQUEST(null, 'get', null, false, "https://axis.uninunez.edu.co/apiaxis/api/mantenimiento/programascurn")
  .then(resp => {
    if (!!(resp) && typeof resp === 'string') {
      window.location = resp;
    }
  }).catch(() => { })


ReactDOM.render(
  <React.StrictMode>
    <ErrorHandler>
      <Router>
        <Suspense fallback={<div>
          <div className="container d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
            <div className="row ">
              <div className="col-12 text-center">
                <div className="spinner-border text-warning mt-5" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
              <div className="mt-2 col-12 text-center">Cargando</div>
            </div>
          </div>
        </div>}>
          <Switch>
            <Route exact path="/" component={ChooseProgram} />
            <Route exact path="/programas" component={AllPrograms} />
            <Route exact path="/solicitar-informacion" component={ProgramInfo} />
            <Route path="*" component={App} />
          </Switch>
        </Suspense>
      </Router>
    </ErrorHandler>
  </React.StrictMode>,
  document.getElementById('root')
);

